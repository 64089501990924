<template>
  <div
    class="card"
    style="margin: 20px;"
    ref="loginform"
    :is-full-page="false"
  >
    <form
      method="post"
      class="card-content loginform"
      @submit.prevent="login"
    >
      <img
        src="/img/logo_weinauktion.png"
        style="padding: 20px;"
      />
      <Notification
        :message="error"
        v-if="error"
      />
      <b-input
        v-model="u"
        placeholder="E-Mail"
        icon="person"
      > </b-input>
      <b-input
        v-model="p"
        type="password"
        placeholder="Password"
        icon="vpn_key"
      >
      </b-input>
      <b-button
        type="is-info"
        native-type="submit"
        id="loginbutton"
      >
        <b-icon icon="login"></b-icon> Login
      </b-button>
      <hr />
      <router-link
        to="/login/password"
        no-prefetch
        class="is-size-7 icon-link"
      >
        <b-icon
          icon="lock-reset"
          style="float: none;"
        /><span class="text">Passwort vergessen?</span>
      </router-link>
    </form>
    <b-loading
      :is-full-page="false"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import Notification from "../_helpers/notification.vue";
export default {
  components: {
    Notification,
  },
  data() {
    return {
      u: "",
      p: "",
      error: null,
      isLoading: false,
    };
  },

  methods: {
    async login() {
      //Ladeanimation anzeigen
      this.isLoading = true;

      let email = this.u;
      let password = this.p;
      let timestamp = Math.floor(new Date().getTime() / 1000);
      var s = this;
      this.$store
        .dispatch("login", {
          email,
          password,
          timestamp,
        })
        .then(function () {
          //console.log('Schließe Loading')
          s.isLoading = false;
          //console.log('Router push nach Login:' + s.$store.getters.originalUrl)
          s.$router.push(s.$store.getters.originalUrl);
          s.$store.commit('setOriginalUrl', '/dashboard');
          return true;
        })
        .catch(function (error) {
          //Flash-Nachricht schon ausgegeben
          if (typeof error.response === "undefined") {
            s.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut.";
          } else if (
            typeof error.response.data != 'undefined' && typeof error.response.data.flash != 'undefined' &&
            error.response.data.flash.length >= 1
          ) {
            //Flash-Meldung durch axios ausgegegen
          } else if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != 'undefined'
          ) {
            s.error = error.response.data.message;
          } else {
            s.error =
              "Server nicht erreichbar. Bitte versuchen Sie es erneut. (2)";
          }
          s.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
form.loginform {
  text-align: center;
}

form.loginform div,
control input.input {
  margin-bottom: 20px;
}

form.loginform a {
  display: inline-block;
}

form.loginform a span.text {
  line-height: 12px;
  display: block;
  visibility: hidden;
}

form.loginform a:hover span.text {
  visibility: visible;
}

form.loginform div.notification {
  font-size: 14px;
  padding: 5px;
}

#loginbutton span {
  margin-right: 5px;
}
</style>
