<template>
  <div>
    <ex4-headline />
    <user-list></user-list>
  </div>
</template>

<script>
import UserList from './list.vue'
import Ex4Headline from '../_helpers/headline.vue'

const UserView = (resolve) => {
  require.ensure(
    ['./view.vue'],
    () => {
      resolve(require('./view.vue'))
    },
    'user'
  )
}

const UserForm = (resolve) => {
  require.ensure(
    ['./form.vue'],
    () => {
      resolve(require('./form.vue'))
    },
    'user'
  )
}

export const userRoutes = [
  
  {
    path: '/view/:id',
    name: 'userView',
    component: UserView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/edit/:id',
    name: 'userEdit',
    component: UserForm,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/add',
    name: 'userAdd',
    component: UserForm,
    meta: {
      requiresAuth: true
    }
  },
  
  
]

export default {
  name: 'user',
  components: {
    UserView,
    UserForm,
    UserList,
    Ex4Headline
  },
  data() {
    return {
      data: [],
      error: '',
      subtitle: '',
      isLoading: true,
      isReady: false,
    }
  },
  mounted: function() {
    this.$store.commit('setHeadline', {
      headline: 'Benutzer',
      search: null,
      submenu: [],
      icon: 'grouppeople'
    })
  },

  methods: {
    userList: async function(data = null) {
      if (this.$route.path != '/users') {
        this.$router.replace('/users')
      }

      if (data != null) {
        //console.log('Daten übergeben')
        this.data = data
      } else {
        var t = this
        this.$http({
          url: '/users',
          method: 'get'
        })
          .then(function(resp) {
            t.data = resp.data.data
            t.isLoading = false
            return true
          })
          .catch(function(error) {
            //console.log(error)
            if (typeof error.response != 'undefined' && typeof error.response.data.message != 'undefined') {
              t.error = error.response.data.message
            } else {
              t.error = 'Server nicht erreichbar. Bitte versuchen Sie es erneut.'
            }
          })
      }
    },
    viewUser: function(id) {
      var t = this
      //this.$router.replace('/users/view/' + id)

      t.$http({
        url: '/users/view/' + id,
        method: 'get'
      })
        .then(function(resp) {
          //console.log( resp.data.data);

          t.$buefy.modal.open({
            parent: t,
            component: UserView,
            hasModalCard: false,
            width: 1250,
            props: { user: resp.data.data, routes: t.$router.options.routes }
          })

          return true
        })
        .catch(function(error) {
          //console.log(error)
          if (typeof error.response != 'undefined' && typeof error.response.data.message != 'undefined') {
            t.error = error.response.data.message
          } else {
            t.error = 'Server nicht erreichbar. Bitte versuchen Sie es erneut.'
          }
        })
    },
    editUser: function(id) {
      var t = this

      //this.$router.replace('/users/edit/' + id)

      t.$http({
        url: '/users/view/' + id,
        method: 'get'
      })
        .then(function(resp) {
          //console.log( resp.data.data);

          t.$buefy.modal.open({
            parent: t,
            component: UserForm,
            hasModalCard: false,
            width: 1250,
            props: { puser: resp.data.data, routes: t.$router.options.routes }
          })

          return true
        })
        .catch(function(error) {
          //console.log(error)
          if (typeof error.response != 'undefined' && typeof error.response.data.message != 'undefined') {
            t.error = error.response.data.message
          } else {
            t.error = 'Server nicht erreichbar. Bitte versuchen Sie es erneut.'
          }
        })
    },
    add: function() {
      this.addUser()
    },
    addUser: function() {
      var t = this
      var puser = {
        company: '',
        firstname: '',
        lastname: '',
        email: '',
        telephone: '',
        position: '',
        active: 0,
        permissions: {}
      }
      var routes = t.$router.options.routes
      for (var i = 0; i < routes.length; i++) {
        if (routes[i].meta.menu != 'main') {
          continue
        }

        var path = routes[i].path.substring(1).replace('*', '')
        puser.permissions[path] = 0
      }
      t.$buefy.modal.open({
        parent: t,
        component: UserForm,
        hasModalCard: false,
        scroll: 'keep',
        props: { puser: puser, routes: t.$router.options.routes }
      })
    },

    deleteUser: function(id, name) {
      this.$buefy.dialog.confirm({
        title: 'Bitte bestätigen!',
        message: 'Wollen Sie Datensatz #' + id + ' "' + name + '" wirklich löschen?',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteUser2(id)
      })
    },
    deleteUser2: function(id) {
      //console.log('delete '+id);
      var t = this
      this.$http({
        url: '/users/delete/' + id,
        method: 'get'
      })
        .then(function(resp) {
          t.userList(resp.data.data)
          //console.log(resp.data)
          return true
        })
        .catch(function(error) {
          //console.log('Shithappens')
          //console.log(error)
          if (typeof error.response != 'undefined' && typeof error.response.data.message != 'undefined') {
            this.$buefy.toast.open({
              message: error.response.data.message,
              type: 'is-danger',
              duration: 5000
            })
          } else {
            this.$buefy.toast.open({
              message: 'Server (API) nicht erreichbar. Bitte versuchen Sie es später erneut.',
              type: 'is-danger',
              duration: 5000
            })
          }
        })
    },
    async saveUser(formdata) {
      //console.log('speichern3')
      this.isLoading = true
      var s = this
      s.$http
        .post('/users/save', { user: formdata })
        .then(function(resp) {
          s.isLoading = false
          s.userList(resp.data.data)

          return true
        })
        .catch(function(error) {
          //console.log('Shithappens')
          if (typeof error.response != 'undefined' && typeof error.response.data.message != 'undefined') {
            s.error = error.response.data.message
          } else {
            s.error = 'Server nicht erreichbar. Bitte versuchen Sie es erneut.'
          }
          s.isLoading = false
          return false
        })
      return false
    }
  }
}
</script>
