<template>
  <div class="topmenu">
    <div class="container">
      <b-navbar>
        <template slot="brand">
          <b-navbar-item @click.prevent="switchFacility()">
            <img :src="pageLogo" alt="Logo" id="logo" />
          </b-navbar-item>
        </template>
        <template slot="start">
          <b-navbar-item
            v-for="item in mainMenuItems"
            v-bind:key="item.id"
            tag="router-link"
            :to="item.path"
          >
            <b-icon :icon="item.meta.icon"></b-icon>
            <span class="text">{{
              typeof item.meta.name[facility] != "undefined"
                ? item.meta.name[facility]
                : item.meta.name
            }}</span>
          </b-navbar-item>
        </template>

        <template slot="end">
          <b-navbar-item tag="a" @click="logout" title="Logout*">
            <b-icon icon="logout" class="red"></b-icon>
            <span class="text is-hidden-tablet">Logout</span>
          </b-navbar-item>
        </template>
      </b-navbar>
    </div>
  </div>
</template>

<script>
import FacilitySelector from "../_helpers/facilityselector.vue"
export default {
  name: "MainMenu",
  components: {
    FacilitySelector
  },

  

  computed: {
    mainMenuItems: function () {
      var t = this
      var menu = []

      var permToCheck = ""
      var permGranted = false
      const facility = t.$store.getters.facility;
      var permissions = t.$store.getters.permissions
      if (typeof permissions[facility] == 'object')
      {
        permissions = permissions[facility];
      }
      else
      {
        permissions = {'wa':{}, 'rp':{}}
      }
      
      for (var i = 0; i < t.$router.options.routes.length; i++) {
        permToCheck = t.$router.options.routes[i].path.substring(1)
        //console.log(permToCheck, t.$router.options.routes[i].meta.menu, t.$router.options.routes[i].meta[t.$store.getters.facility])
        permGranted = false
        if (
          typeof permissions[permToCheck] != "undefined" &&
          (permissions[permToCheck] === 1 || permissions[permToCheck] === '1')
        ) {
          permGranted = true
          //console.log('granted')

        }
        if (
          t.$router.options.routes[i].meta.menu == "main" &&
          permGranted === true &&
          t.$router.options.routes[i].meta[facility] == true
        )
          menu.push(t.$router.options.routes[i])
      }
      //console.log(menu);

      return menu
    },
    pageLogo: function () {
      return this.$store.getters.config.page.logo[this.$store.getters.facility]
    },
    facility: function(){
       return this.$store.getters.facility;
    }
  },
  methods: {
    logout: function () {
      //console.log('Logout-Method in MyMenue-Component')
      this.$store.dispatch("logout")
      this.$router.push({ path: "/login" })
    },
    switchFacility: function () {
      var t = this
      t.$buefy.modal.open({
        parent: t,
        component: FacilitySelector,
        hasModalCard: false
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.topmenu {
  border-bottom: 1px solid #bbbbbb;
}

div.navbar-brand a {
  padding: 0px 8px;
}

div.navbar-brand a img {
  height: 40px !important;
  min-height: 40px;
}

div.topmenu nav div.navbar-menu a span.icon {
  padding-right: 20px;
  padding-left: 20px;
}
@media (min-width: 1024px) {
  div.topmenu nav.navbar {
    min-height: 60px !important;
  }
  div.topmenu nav div.navbar-menu a.navbar-item {
    text-align: center;
    width: 90px;
    flex-wrap: wrap;
    color: #8f91ac;
    padding-top: 8px;
  }

  div.topmenu nav div.navbar-menu a.router-link-active,
  div.topmenu nav div.navbar-menu a:hover {
    color: #3d4c99 !important;
    border-bottom: 2px solid #3d4c99;
  }

  div.topmenu nav div.navbar-menu a span.text {
    display: none;
    font-size: 11px;
    width: 100%;
    padding: 0px 0px 0px 0px;
    color: #ffffff;
    text-transform: uppercase;
  }

  div.topmenu nav div.navbar-menu a:hover span.text {
    color: #3d4c99;
    display: block !important;
  }

  div.topmenu nav div.navbar-menu a span.icon {
    display: block !important;
    margin-left: auto;
    margin-right: auto;
    padding: 3px 0px;
  }

  div.topmenu nav div.navbar-menu a span.icon i.mdi-24px.mdi::before {
    font-size: 48px;
  }

  div.topmenu nav div.navbar-end a {
    text-align: center;
    flex-wrap: wrap;
  }
}
@media (min-width: 1216px) {
}
</style>
