<template>
  <b-table
    :data="data"
    :loading="isLoading"
    :striped="true"
    :hoverable="true"
    icon-pack="icm"
    :mobile-cards="false"
    :row-class="(row, index) => (row.active == 1 ? 'green' : 'red')"
  >
    <b-table-column
      v-slot="props"
      field="id"
      label="Id"
      cell-class="status"
      numeric
      sortable
    >
      {{ $utilities.padLeftWithNull(props.row.id) }}
    </b-table-column>

    <b-table-column v-slot="props" field="id" label="Name">
      {{ props.row.lastname }}, {{ props.row.firstname }}
    </b-table-column>

    <b-table-column v-slot="props" field="ts" label="E-Mail">
      {{ props.row.email }}
    </b-table-column>

    <b-table-column v-slot="props" field="ts" label="Rechte"
      ><b-tag class="min-width-40" type="is-info">WA</b-tag>
      <span v-html="translatePerm(props.row.permissions, 'wa')"></span><br />
      <b-tag class="min-width-40" type="is-info">RP</b-tag>
      <span v-html="translatePerm(props.row.permissions, 'rp')"></span
    ></b-table-column>

    <b-table-column
      v-slot="props"
      label="A"
      class="iconlink"
      field="link1"
      header-class="iconlink"
    >
      <a
        :href="`/users/view/${props.row.id}`"
        title="Ansehen"
        @click.prevent="viewUser(props.row.id)"
        ><b-icon icon="find_in_page"></b-icon
      ></a>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="B"
      class="iconlink"
      field="link2"
      header-class="iconlink"
    >
      <a
        :href="`/users/edit/${props.row.id}`"
        title="Bearbeiten"
        @click.prevent="editUser(props.row.id)"
        ><b-icon icon="square-edit-outline"></b-icon
      ></a>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="L"
      class="iconlink"
      field="link3"
      header-class="iconlink"
    >
      <a
        @click.prevent="
          deleteUser(props.row.id, props.row.firstname + ' ' + props.row.lastname)
        "
        title="Löschen"
        ><b-icon icon="delete_forever"></b-icon
      ></a>
    </b-table-column>
  </b-table>
</template>

<script>
export default {
  name: "userList",
  data() {
    return {
      error: "",
    };
  },
  computed: {
    data: function () {
      return this.$parent.data;
    },
    isLoading: function () {
      return this.$parent.isLoading;
    },
  },
  created: function () {
    this.$store.commit("setHeadline", {
      subheadline: "Übersicht",
      add: "/users/add",
      search: null,
      submenu: [],
    });

    this.$parent.userList();
  },
  methods: {
    deleteUser: function (id, name) {
      this.$parent.deleteUser(id, name);
    },
    deleteUser2: function (id) {
      this.$parent.deleteUser2(id);
    },

    viewUser: function (id) {
      this.$parent.viewUser(id);
    },
    editUser: function (id) {
      this.$parent.editUser(id);
    },
    translatePerm: function (userPermissions, facility) {
      if (typeof userPermissions == "string") {
        userPermissions = JSON.parse(userPermissions);
      }

      var permList = [];
      var color = "icon-grey";
      var path = "";
      var routes = this.$router.options.routes;
      for (var i = 0; i < routes.length; i++) {
        if (routes[i].meta.menu != "main") {
          continue;
        }

        path = routes[i].path.substring(1).replace("*", "");
        if (routes[i].meta[facility] !== true) {
          color = "icon-grey";
        } else if (
          typeof userPermissions[facility] == "object" &&
          typeof userPermissions[facility][path] != "undefined" &&
          (userPermissions[facility][path] === 1 ||
            userPermissions[facility][path] === "1")
        ) {
          color = "icon-green";
        } else {
          color = "icon-red";
        }

        permList.push(
          '<i class="icm icm-' +
            routes[i].meta.icon +
            " " +
            color +
            '" title="' +
            ((typeof routes[i].meta.name == "object")
            ? routes[i].meta.name[facility]
            : routes[i].meta.name) + '"></i>'
        );
      }

      return permList.join(" &nbsp; ");
    },
  },
};
</script>
