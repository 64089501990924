<template>
  <div>
    <div
      class="columns is-multiline infoboxen"
      v-if="this.$store.getters.facility == 'wa'"
    >
      <div class="column">
        <div class="box notification is-link is-light">
          <b-icon icon="equalizer" size="is-large" class="icon-left"></b-icon>
          <div class="heading">
            Jahresstatistik {{ new Date().getFullYear() }}
          </div>
          <div class="title">Auktionen</div>
          <div class="level">
            <div class="level-item">
              <div>
                <div class="heading">Auktionen<br />total</div>
                <div class="title is-5">{{ auctionStats.auctions }}</div>
              </div>
            </div>
            <div class="level-item">
              <div>
                <div class="heading">Lots<br />total</div>
                <div class="title is-5">{{ auctionStats.lots }}</div>
              </div>
            </div>
            <div class="level-item">
              <div>
                <div class="heading">Lots<br />versteigert</div>
                <div class="title is-5">{{ auctionStats.lotssold }}</div>
              </div>
            </div>
            <div class="level-item">
              <div>
                <div class="heading">Lots<br />unversteigert</div>
                <div class="title is-5">{{ auctionStats.lotsnotsold }}</div>
              </div>
            </div>
            <div class="level-item">
              <div>
                <div class="heading">Lots<br />offen</div>
                <div class="title is-5">{{ auctionStats.lotsopen }}</div>
              </div>
            </div>
            <div class="level-item">
              <div>
                <div class="heading">Lots<br />gestoppt</div>
                <div class="title is-5">{{ auctionStats.lotsstopped }}</div>
              </div>
            </div>
            <div class="level-item">
              <div>
                <div class="heading">Summe<br />Zuschläge</div>
                <div class="title is-5">
                  {{ nf.format(auctionStats.revenues) }}€
                </div>
              </div>
            </div>
            <div class="level-item">
              <div>
                <div class="heading">Gebühren<br />Einlieferungen</div>
                <div class="title is-5">
                  {{ nf.format(auctionStats.consignmentFees) }}€
                </div>
              </div>
            </div>
            <div class="level-item">
              <div>
                <div class="heading">Gebühren<br />Auktionen</div>
                <div class="title is-5">
                  {{ nf.format(auctionStats.auctionFees) }}€
                </div>
              </div>
            </div>
          </div>
          <div class="clear"></div>
          <b-loading
            :is-full-page="false"
            :active.sync="auctionStats.isLoading"
            :can-cancel="false"
          ></b-loading>
        </div>
      </div>
    </div>
    <div
      class="columns is-multiline infoboxen"
      v-if="this.$store.getters.facility == 'wa'"
    >
      <!-- Aktuell  laufende Auktienon -->
      <div class="column">
        <div class="box notification is-primary">
          <b-icon icon="gavel" size="is-large" class="icon-left"></b-icon>
          <div class="heading">Laufende</div>
          <div class="title">Lots</div>
          <div class="level">
            <div class="level-item">
              <div>
                <div class="heading">Anzahl</div>
                <div class="title is-5">{{ currentAuctions.auctions }}</div>
              </div>
            </div>
            <div class="level-item">
              <div>
                <div class="heading">Gebote</div>
                <div class="title is-5">{{ currentAuctions.bids }}</div>
              </div>
            </div>
            <div class="level-item">
              <div>
                <div class="heading">Summe</div>
                <div class="title is-5">
                  {{ nf.format(currentAuctions.revenues) }}€
                </div>
              </div>
            </div>
          </div>
          <div class="clear"></div>
          <b-loading
            :is-full-page="false"
            :active.sync="currentAuctions.isLoading"
            :can-cancel="false"
          ></b-loading>
        </div>
      </div>

      <!-- 7 Tage beendete Auktionen -->
      <div class="column">
        <div class="box notification is-link">
          <b-icon icon="gavel" size="is-large" class="icon-left"></b-icon>
          <div class="heading">14-Tage-Statistik Beendete</div>
          <div class="title">Lots</div>

          <div class="level">
            <div class="level-item">
              <div>
                <div class="heading">Beendet</div>
                <div class="title is-5">
                  {{ finishedAuctions14Days.auctions }}
                </div>
              </div>
            </div>
            <div class="level-item">
              <div>
                <div class="heading">Gebote</div>
                <div class="title is-5">{{ finishedAuctions14Days.bids }}</div>
              </div>
            </div>

            <div class="level-item is-5">
              <div>
                <div class="heading">Summe</div>
                <div class="title is-5">
                  {{ nf.format(finishedAuctions14Days.revenues) }}€
                </div>
              </div>
            </div>
          </div>
          <div class="clear"></div>
          <b-loading
            :is-full-page="false"
            :active.sync="finishedAuctions14Days.isLoading"
            :can-cancel="false"
          ></b-loading>
        </div>
      </div>

      <!-- Lager -->
      <div class="column">
        <div class="box notification is-info">
          <b-icon
            icon="package-variant-closed"
            size="is-large"
            class="icon-left"
          ></b-icon>
          <div class="heading">Flaschen</div>
          <div class="title">Lager</div>
          <div class="level">
            <div class="level-item">
              <div>
                <div class="heading">Total</div>
                <div class="title is-5">{{ stockStatus.bottles }}</div>
              </div>
            </div>
            <div class="level-item">
              <div>
                <div class="heading">Verfügbar</div>
                <div class="title is-5">{{ stockStatus.free }}</div>
              </div>
            </div>
            <div class="level-item">
              <div>
                <div class="heading">Wert Min.</div>
                <div class="title is-5">
                  {{ nf.format(stockStatus.value) }}€
                </div>
              </div>
            </div>
          </div>
          <div class="clear"></div>
          <b-loading
            :is-full-page="false"
            :active.sync="stockStatus.isLoading"
            :can-cancel="false"
          ></b-loading>
        </div>
      </div>

      <!-- Kunden -->
      <div class="column">
        <div class="box notification is-info">
          <b-icon
            icon="contact_mail"
            size="is-large"
            class="icon-left"
          ></b-icon>
          <div class="heading">Jahres-Statistik</div>
          <div class="title">Kunden</div>
          <div class="level">
            <div class="level-item">
              <div>
                <div class="heading">Neu</div>
                <div class="title is-5">{{ customerStats.new }}</div>
              </div>
            </div>
            <div class="level-item">
              <div>
                <div class="heading">Logins</div>
                <div class="title is-5">{{ customerStats.logins }}</div>
              </div>
            </div>
          </div>
          <div class="clear"></div>
          <b-loading
            :is-full-page="false"
            :active.sync="customerStats.isLoading"
            :can-cancel="false"
          ></b-loading>
        </div>
      </div>
    </div>

    <div
      class="columns is-multiline charts"
      v-if="this.$store.getters.facility == 'wa'"
    >
      <div class="column is-half">
        <div class="notification" id="chart3">
          <b-icon icon="dollar" size="is-large" class="icon-left"></b-icon>
          <div class="heading">pro Monat abgerechnete</div>
          <div class="title">Auktionsgebote</div>
          <barchart
            :chart-data="chartRevenuesStatistic"
            :options="chartOptions"
            :updated="chartRevenuesStatistic.updated"
            id="revenues-chart"
          ></barchart>
        </div>
      </div>
      <div class="column is-half">
        <div class="notification" id="chart3">
          <b-icon icon="moneybag" size="is-large" class="icon-left"></b-icon>
          <div class="heading">pro Monat abgerechnete</div>
          <div class="title">Einlieferungs- & Auktionsgebühren</div>
          <barchart
            :chart-data="chartFeesStatistic"
            :options="chartOptions"
            :updated="chartFeesStatistic.updated"
            id="fees-chart"
          ></barchart>
        </div>
      </div>

      <div class="column is-half">
        <div class="notification" id="chart1">
          <b-icon icon="bidding" size="is-large" class="icon-left"></b-icon>
          <div class="heading">{{ daysForCharts }}-Tage-Statistik</div>
          <div class="title">Anzahl Gebote</div>
          <barchart
            :chart-data="chartBidStatistic"
            :options="chartOptions"
            :updated="chartBidStatistic.updated"
            id="bid-count-chart"
          ></barchart>
        </div>
      </div>
      <div class="column is-half">
        <div class="notification" id="chart2">
          <b-icon icon="view" size="is-large" class="icon-left"></b-icon>
          <div class="heading">{{ daysForCharts }}-Tage-Statistik</div>
          <div class="title">Auf Beobachtungsliste</div>
          <barchart
            :chart-data="chartOnwatchlistsStatistic"
            :options="chartOptions"
            :updated="chartOnwatchlistsStatistic.updated"
            id="watch-count-chart"
          ></barchart>
        </div>
      </div>
    </div>
    <div class="columns" v-if="this.$store.getters.facility == 'rp'">
      <div class="column is-half">
        <div class="box notification is-primary">
          <b-icon
            icon="package-variant-closed"
            size="is-large"
            class="icon-left"
          ></b-icon>
          <div class="heading">
            Lager (inkl. verkauft aber noch nicht ausgeliefert)
          </div>
          <div class="title">Bestand total</div>
          <div class="level">
            <div class="level-item">
              <div>
                <div class="heading">Flaschen</div>
                <div class="title is-5">
                  {{
                    stockStatusRp.bottlesTotal == null
                      ? 0
                      : stockStatusRp.bottlesTotal
                  }}
                </div>
              </div>
            </div>
            <div class="level-item">
              <div>
                <div class="heading">Einkaufswert</div>
                <div class="title is-5">
                  {{
                    stockStatusRp.bottlesTotalValue == null
                      ? 0
                      : stockStatusRp.bottlesTotalValue
                  }}
                  €
                </div>
              </div>
            </div>
          </div>
          <div class="clear"></div>
          <b-loading
            :is-full-page="false"
            :active.sync="stockStatusRp.isLoading"
            :can-cancel="false"
          ></b-loading>
        </div>
      </div>

      <div class="column is-half">
        <div class="box notification is-primary">
          <b-icon
            icon="package-variant-closed"
            size="is-large"
            class="icon-left"
          ></b-icon>
          <div class="heading">Lager (unverkauft)</div>
          <div class="title">Bestand verfügbar</div>
          <div class="level">
            <div class="level-item">
              <div>
                <div class="heading">Flaschen</div>
                <div class="title is-5">
                  {{
                    stockStatusRp.bottlesAvailable == null
                      ? 0
                      : stockStatusRp.bottlesAvailable
                  }}
                </div>
              </div>
            </div>
            <div class="level-item">
              <div>
                <div class="heading">Einkaufswert</div>
                <div class="title is-5">
                  {{
                    stockStatusRp.bottlesAvailableValue == null
                      ? 0
                      : stockStatusRp.bottlesAvailableValue
                  }}
                  €
                </div>
              </div>
            </div>
          </div>
          <div class="clear"></div>
          <b-loading
            :is-full-page="false"
            :active.sync="stockStatusRp.isLoading"
            :can-cancel="false"
          ></b-loading>
        </div>
      </div>
    </div>
    <div class="columns charts is-multiline">
      <div class="column is-half">
        <div class="notification">
          <b-icon icon="coin-euro" size="is-large" class="icon-left"></b-icon>
          <div class="heading">Umsatz</div>
          <div class="title">Top-Kunden 90 Tage</div>
          <b-table
            :data="customersByRevenue90.data"
            :loading="customersByRevenue90.isLoading"
            :striped="true"
            :hoverable="true"
            :mobile-cards="false"
            :row-class="(row, index) => row.customer_id"
          >
            <b-table-column
              v-slot="props"
              label="KdNr."
              cell-class="status"
              numeric
              sortable
            >
              {{ $utilities.padLeftWithNull(props.row.customer_id) }}
            </b-table-column>

            <b-table-column v-slot="props" label="Name" sortable>
              {{ props.row.name }}
            </b-table-column>

            <b-table-column v-slot="props" label="Rech." sortable numeric>
              {{ props.row.invoices }}
            </b-table-column>

            <b-table-column v-slot="props" label="Umsatz" sortable numeric>
              {{ props.row.revenue }}€
            </b-table-column>
          </b-table>
        </div>
      </div>
      <div class="column is-half">
        <div class="notification">
          <b-icon icon="coin-euro" size="is-large" class="icon-left"></b-icon>
          <div class="heading">Umsatz</div>
          <div class="title">Top-Kunden 365 Tage</div>
          <b-table
            :data="customersByRevenue365.data"
            :loading="customersByRevenue365.isLoading"
            :striped="true"
            :hoverable="true"
            :mobile-cards="false"
            :row-class="(row, index) => row.customer_id"
          >
            <b-table-column
              v-slot="props"
              label="KdNr."
              cell-class="status"
              numeric
              sortable
            >
              {{ $utilities.padLeftWithNull(props.row.customer_id) }}
            </b-table-column>

            <b-table-column v-slot="props" label="Name" sortable>
              {{ props.row.name }}
            </b-table-column>

            <b-table-column v-slot="props" label="Rech." sortable numeric>
              {{ props.row.invoices }}
            </b-table-column>

            <b-table-column v-slot="props" label="Umsatz" sortable numeric>
              {{ props.row.revenue }}€
            </b-table-column>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import Linechart from "../_helpers/linechart.vue"
import Barchart from "../_helpers/barchart.vue"
import debounce from "lodash/debounce"

export default {
  name: "Dashboard",
  components: {
    //Linechart,
    Barchart
  },

  data() {
    return {
      nf: Intl.NumberFormat(),
      daysForCharts: 30,
      currentAuctions: {
        isLoading: true,
        auctions: 0,
        bids: 0,
        revenues: 0
      },
      finishedAuctions14Days: {
        isLoading: true,
        auctions: 0,
        bids: 0,
        revenues: 0
      },
      customerStats: {
        isLoading: true,
        new: 0,
        logins: 0
      },
      stockStatus: {
        isLoading: true,
        bottles: 0,
        free: 0,
        value: 0
      },
      stockStatusRp: {
        isLoading: true,
        bottlesTotal: 0,
        bottlesTotalValue: 0,
        bottlesAvailable: 0,
        bottlesAvailableValue: 0
      },
      auctionStats: {
        isLoading: true,
        auctions: 0,
        lots: 0,
        lotssold: 0,
        lotsnotsold: 0,
        lotsopen: 0,
        lotsstopped: 0,
        revenues: 0,
        auctionFees: 0,
        consignmentFees: 0
      },
      customersByRevenue90: { data: [], isLoading: true },
      customersByRevenue365: { data: [], isLoading: true },
      data: [],
      data2: [],

      chartBidStatistic: {
        updated: 0,
        labels: [],
        datasets: [
          {
            borderColor: "#B9C2D9",
            backgroundColor: "rgba(0,209,178, 1)",
            data: [],
            fill: true,
            lineTension: 0
          }
        ]
      },
      chartOnwatchlistsStatistic: {
        updated: 0,
        labels: [],
        datasets: [
          {
            borderColor: "#B9C2D9",
            backgroundColor: "rgba(0,209,178, 1)",
            data: [],
            fill: true,
            lineTension: 0
          }
        ]
      },
      chartRevenuesStatistic: {
        updated: 0,
        labels: [],
        datasets: [
          {
            borderColor: "#B9C2D9",
            backgroundColor: "rgba(50,152,220, 1)",
            data: [],
            fill: true
          }
        ]
      },
      chartFeesStatistic: {
        updated: 0,
        labels: [],
        datasets: [
          {
            borderColor: "#B9C2D9",
            backgroundColor: "rgba(50,152,220, 1)",
            data: [],
            fill: true
          }
        ]
      },
      chartOptions: {
        id: "hurz1",
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          mode: "index",
          intersect: false,
          callbacks: {
            label: function (tooltipItem) {
              return tooltipItem.yLabel
            }
          }
        },
        hover: {
          mode: "nearest",
          intersect: true
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    }
  },

  computed: {
    facility() {
      return this.$store.getters.facility
    }
  },
  watch: {
    facility(value) {
      this.loadData()
    }
  },
  created: function () {
    this.loadData()
  },

  methods: {
    loadData: function () {
      var t = this

      if (t.$store.getters.facility == "wa") {
        t.getCurrentAuctions()
        t.getFinishedAuctions14Days()
        t.getStockStatusWa()
        t.getCustomerStats()
        t.getAuctionYearStats()
        t.getBidStatistic()
        t.getBidCustomersByRevenue("wa", 90, 300)
        t.getBidCustomersByRevenue("wa", 365, 350)
      } else if (t.$store.getters.facility == "rp") {
        t.getBidCustomersByRevenue("rp", 90, 300)
        t.getBidCustomersByRevenue("rp", 365, 350)
        t.getStockStatusRp()
      }
    },
    createData(no, max) {
      if (no == 1) {
        return 0
      }
      const base = Math.floor(max / 2)
      var result = []
      for (var i = 0; i < no; i++) {
        result.push(0)
      }
      return result
    },
    getDaysFromToday(noOfDays) {
      var days = []
      var endDate = new Date()
      var currentDate = new Date()
      var mm = 0
      var dd = 0
      currentDate.setDate(currentDate.getDate() - noOfDays + 1)
      //console.log(endDate);
      //console.log(currentDate);
      while (currentDate <= endDate) {
        mm = currentDate.getMonth() + 1
        if (mm <= 9) {
          mm = "0" + mm
        }
        dd = currentDate.getDate()
        if (dd <= 9) {
          dd = "0" + dd
        }
        days.push(dd + "." + mm + ".")
        currentDate.setDate(currentDate.getDate() + 1)
      }

      return days
    },
    getCurrentAuctions: async function () {
      var t = this
      t.currentAuctions.isLoading = true
      t.$http
        .get("/dashboard/getcurrentauctions")
        .then(function (resp) {
          t.currentAuctions = resp.data.data
          t.currentAuctions.isLoading = false
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          t.isFetching = false
        })
    },
    getFinishedAuctions14Days: debounce(async function () {
      var t = this
      t.finishedAuctions14Days.isLoading = true
      t.$http
        .get("/dashboard/getfinished14days")
        .then(function (resp) {
          t.finishedAuctions14Days = resp.data.data
          t.finishedAuctions14Days.isLoading = false
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          t.isFetching = false
        })
    }, 50),
    getStockStatusWa: debounce(async function () {
      var t = this
      t.stockStatus.isLoading = true
      t.$http
        .get("/dashboard/getstockstatuswa")
        .then(function (resp) {
          t.stockStatus = resp.data.data
          t.stockStatus.isLoading = false
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          t.isFetching = false
        })
    }, 100),
    getStockStatusRp: debounce(async function () {
      var t = this
      t.stockStatusRp.isLoading = true
      t.$http
        .get("/dashboard/getstockstatusrp")
        .then(function (resp) {
          t.stockStatusRp = resp.data.data
          t.stockStatus.isLoading = false
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          t.isFetching = false
        })
    }, 100),
    getCustomerStats: debounce(async function () {
      var t = this
      t.customerStats.isLoading = true
      t.$http
        .post("/dashboard/getcustomerstats")
        .then(function (resp) {
          t.customerStats = resp.data.data
          t.customerStats.isLoading = false
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          t.isFetching = false
        })
    }, 150),
    getAuctionYearStats: debounce(async function () {
      var t = this
      t.auctionStats.isLoading = true
      t.$http
        .post("/dashboard/getauctionyearstats")
        .then(function (resp) {
          t.auctionStats = resp.data.data
          t.auctionStats.isLoading = false
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          t.isFetching = false
        })
    }, 200),
    getBidStatistic: debounce(async function () {
      var t = this

      t.$http
        .post("/dashboard/get-bid-statistic/" + t.daysForCharts)
        .then(function (resp) {
          t.chartBidStatistic.labels = resp.data.data.labels
          t.chartBidStatistic.datasets[0].data = resp.data.data.bids
          t.chartBidStatistic.updated++

          t.chartOnwatchlistsStatistic.labels = resp.data.data.labels
          t.chartOnwatchlistsStatistic.datasets[0].data =
            resp.data.data.onwatchlists
          t.chartOnwatchlistsStatistic.updated++

          t.chartRevenuesStatistic.labels = resp.data.data2.labels
          t.chartRevenuesStatistic.datasets[0].data = resp.data.data2.revenues
          t.chartRevenuesStatistic.updated++

          t.chartFeesStatistic.labels = resp.data.data2.labels
          t.chartFeesStatistic.datasets[0].data = resp.data.data2.fees
          t.chartFeesStatistic.updated++
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          t.isFetching = false
        })
    }, 250),

    getBidCustomersByRevenue: async function (facility, days, sleep) {
      var t = this

      t["customersByRevenue" + days].data = []
      t["customersByRevenue" + days].isLoading = true

      t.$http
        .post("/dashboard/get-customers-by-revenue/" + facility + "/" + days)
        .then(function (resp) {
          t["customersByRevenue" + days].data = resp.data.data
          t["customersByRevenue" + days].isLoading = false
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          t.isFetching = false
          return []
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
div.infoboxen div.column {
  text-align: right;
}
div.infoboxen div.column div.title {
  line-height: 0.95rem;
}

div.box span.icon {
  opacity: 0.5;
}

div.level {
  margin-bottom: 0.25rem !important;
}

div.level-item {
  float: right;
}

div.heading {
  margin-bottom: 0px;
}
div.notification div.heading {
  text-align: right;
}
div.notification {
  padding: 1rem !important;
}

div.notification div.level-item {
  justify-content: right;
}

div.charts div.card {
  background-color: $lightGray;
}
div.title {
  font-size: 28px;
  text-align: right;
}
span.icon {
  float: left;
}

.curve-btc {
  .stroke {
    stroke: #209cee !important;
    stroke-width: 2;
  }

  .fill {
    fill: #209cee;
    fill-opacity: 0.4;
  }
}

div#chart1 canvas,
div#chart2 canvas {
  max-height: 300px;
}
</style>
