<template>
  <div>
    <ex4-headline />
    <transition name="contenttrans" mode="out-in" appear>
      <router-view />
    </transition>
  </div>
</template>

<script>
//import ConsignmentList from "./list.vue";
import Ex4Headline from "../_helpers/headline.vue"

const ConsignmentList = (resolve) => {
  require.ensure(
    ["./list.vue"],
    () => {
      resolve(require("./list.vue"))
    },
    "consignments"
  )
}

const ConsignmentView = (resolve) => {
  require.ensure(
    ["./view.vue"],
    () => {
      resolve(require("./view.vue"))
    },
    "consignments"
  )
}

const ConsignmentAdd = (resolve) => {
  require.ensure(
    ["./add.vue"],
    () => {
      resolve(require("./add.vue"))
    },
    "consignments"
  )
}

export const consignmentRoutes = [
  {
    path: "",
    redirect: "index"
  },
  {
    path: "index",
    name: "consignmentList",
    component: ConsignmentList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "view/:id",
    name: "consignmentView",
    component: ConsignmentView,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "add",
    name: "consignmentAdd",
    component: ConsignmentAdd,
    meta: {
      requiresAuth: true
    }
  }
]

export default {
  name: "Consignments",
  components: {
    ConsignmentAdd,
    Ex4Headline
  },
  data() {
    return {
      data: {
        count: 0,
        perpage: 50,
        data: [],
        page: 1,
        order: {
          field: "Consignment.status",
          direction: "ASC"
        }
      },
      error: "",
      subtitle: "",
      isLoading: true,
      searchphrase: "",
      modal: false,
      consignment: {}
    }
  },
  created: function() {
    this.$store.commit("setHeadline", {
      headline: (this.$store.getters.facility == 'rp') ? 'Ankauf' :'Einlieferungen',
      search: "search",
      add: "/consignments/add",
      submenu: [],
      icon: "add_shopping_cart",
      subheadline: "Übersicht"
    })
  },

  methods: {
    search: function() {
      this.consignmentList()
    },
    consignmentList: async function(data = null) {
      //console.log(this.$store.getters.config);

      this.isLoading = true
      if (data != null) {
        //console.log('Daten übergeben')
        this.data = data
        this.isLoading = false
      } else {
        var t = this
        this.$http
          .post("/consignments", {
            page: t.data.page,
            search: t.searchphrase,
            order: t.data.order
          })
          .then(function(resp) {
            t.data = resp.data.data
            t.isLoading = false
            return true
          })
          .catch(function(error) {
            //console.log(error)
            if (
              typeof error.response != "undefined" &&
              typeof error.response.data.message != "undefined"
            ) {
              t.error = error.response.data.message
            } else {
              t.error =
                "Server nicht erreichbar. Bitte versuchen Sie es erneut."
            }
          })
      }
    },
    consignmentView: function(id) {
      var t = this
      t.consignment = {}
      t.$router
        .push({
          path: "/consignments/view/" + id
        })
        .catch((err) => {})
      t.$http({
        url: "/consignments/view/" + id,
        method: "get"
      })
        .then(function(resp) {
          t.consignment = resp.data.data
          t.parseImages()

          return true
        })
        .catch(function(error) {
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
        })
    },
    add: function() {
      this.$router
        .push({
          path: "/consignments/add"
        })
        .catch((err) => {})
    },
    consignmentCancel: function(id) {
      this.$buefy.dialog.confirm({
        title: "Bitte bestätigen!",
        confirmText: "Einlieferung abbrechen",
        message: "Wollen Sie die Einlieferung #" + id + " wirklich abbrechen?",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.consignmentCancel2(id)
      })
    },
    consignmentCancel2: function(id) {
      //console.log('cancel '+id);
      var t = this
      this.$http
        .post("/consignments/cancel/" + id, {
          page: t.data.page,
          search: t.searchphrase,
          order: t.data.order
        })
        .then(function(resp) {
          if (t.$router.currentRoute.fullPath != "/consignments/index") {
            t.$router.push("/consignments/index")
          } else {
            t.consignmentList()
          }

          return true
        })
        .catch(function(error) {
          //console.log('Shithappens')
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.$buefy.toast.open({
              message: error.response.data.message,
              type: "is-danger",
              duration: 5000
            })
          } else {
            t.$buefy.toast.open({
              message:
                "Server (API) nicht erreichbar. Bitte versuchen Sie es später erneut.",
              type: "is-danger",
              duration: 5000
            })
          }
        })
    },
    parseImages: function() {
      var t = this
      if (
        typeof t.consignment.sequences == "object" &&
        t.consignment.sequences.length >= 1
      ) {
        for (var c = 0; c < t.consignment.sequences.length; c++) {
          if (
            typeof t.consignment.sequences[c].articles == "object" &&
            t.consignment.sequences[c].articles.length >= 1
          ) {
            for (
              var i = 0;
              i < t.consignment.sequences[c].articles.length;
              i++
            ) {
              if (
                typeof t.consignment.sequences[c].articles[i].images ==
                  "string" &&
                t.consignment.sequences[c].articles[i].images.length > 15
              ) {
                t.consignment.sequences[c].articles[i].images = JSON.parse(
                  t.consignment.sequences[c].articles[i].images
                )
                if (
                  typeof t.consignment.sequences[c].articles[i].images ==
                  "string"
                ) {
                  t.consignment.sequences[c].articles[i].images = []
                }
              }
            }
          }
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss"></style>
