import Axios from 'axios'
import extranet from '../config.js'





export default new Axios.create({
  /* eslint-disable-line */
  /*baseURL: 'http://api.weinauktion2.loc/api/',*/
  baseURL: extranet.page.apiBaseUrl,
  timeout: 10000,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})


//Vue.use(Axios)