<template>
  <div class="card">
    <div class="card-header">
      <h1 class="card-header-title">
        Warum wird die Verauktionierung des Lots abgebrochen:
      </h1>
    </div>
    <div class="card-content">
      <label
        >Bitte geben Sie aus Gründen der Nachvollziehbarkeit an, warum das Lot abgebrochen wird.</label
      >
      <b-input v-model="reasonForCancel" placeholder="Bitte angeben"> </b-input>
    </div>
    <div class="card-footer">
      <div class="column">
        <button class="button" type="button" @click="$parent.close()">
          Schließen
        </button>
        &nbsp;
        <b-button
          v-if="readyToSave"
          type="is-danger"
          icon-left="cancel"
          @click="cancelAuction2()"
          >Lot jetzt abbrechen</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CancelAuction",
  props: ['id', 'title'],
  data: function() {
    return {
      reasonForCancel: "",
      submitted: false,
      error: false
    }
  },
  computed: {
    readyToSave: function() {
      if (this.reasonForCancel.length > 3) {
        return true
      }
      return false
    }
  },
  created: function() {},
  methods: {
    async cancelAuction2() {
      this.isLoading = true
      var t = this
      t.$http
        .post("/auction/cancel/"+ t.id, {
          id: t.id,
          reasonForCancel: t.reasonForCancel
        })
        .then(function(resp) {
          t.isLoading = false
          t.$router.push({ path: "/auctions" });         
          
          t.$parent.close()
          window.scrollTo(0, 0)

          return true
        })
        .catch(function(error) {
          //console.log('Shithappens')
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
          t.isLoading = false
          return false
        })
    }
  }
}
</script>

<style></style>
