<script>
import { Bar, mixins } from "vue-chartjs"
const { reactiveProp } = mixins

export default {
  name: "Barchart",
  mixins: [reactiveProp],
  extends: Bar,
  props: ["chartData", "options", "updated"],
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    updated: function() {
      this.renderChart(this.chartData, this.options)
    }
  }
}
</script>

<style></style>
