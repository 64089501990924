var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-table',{attrs:{"data":_vm.data.data,"loading":_vm.isLoading,"striped":true,"hoverable":true,"range-before":5,"range-after":5,"paginated":"","backend-pagination":"","pagination-position":"both","total":_vm.data.count,"per-page":_vm.data.perpage,"current-page":_vm.data.page,"aria-next-label":"Weiter","aria-previous-label":"Zurück","aria-page-label":" Seite ","aria-current-label":"Aktuelle Seite","backend-sorting":"","default-sort":[_vm.data.sortField, _vm.data.sortOrder],"icon-pack":"icm","mobile-cards":false,"row-class":(row, index) => (row.active == 1 ? 'green' : 'red')},on:{"page-change":_vm.onPageChange,"update:currentPage":function($event){return _vm.$set(_vm.data, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.data, "page", $event)},"sort":_vm.onSort}},[_c('b-table-column',{attrs:{"field":"id","label":"Id","cell-class":"status","numeric":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm.$utilities.padLeftWithNull(props.row.id))+" ")]}}])}),_c('b-table-column',{attrs:{"field":"name","label":"Name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.name)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"city","label":"Ort","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(typeof props.row.country.code == "undefined" || props.row.country.code == null ? "??" : props.row.country.code)+" » "+_vm._s(props.row.zip)+" "+_vm._s(props.row.city)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Zuordn.","field":"link5","title":"Zuordnung zu Firmen"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{class:{
        green: props.row.customer_wa == 1,
        grey: props.row.customer_wa == 0,
      },attrs:{"title":"Kunde Weinauktionator"}},[_vm._v("WA")]),_vm._v("  "),_c('span',{class:{
        green: props.row.customer_rp == 1,
        grey: props.row.customer_rp == 0,
      },attrs:{"title":"Kunde Riepenau"}},[_vm._v("RP")])]}}])}),_c('b-table-column',{attrs:{"label":"Status","field":"link6","title":"Lieferadresse/Export"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-icon',{class:{
        green: props.row.shipping_address == 1,
        grey: props.row.shipping_address == 0,
      },attrs:{"icon":"delivery","title":"Separate Lieferadresse"}}),_c('b-icon',{class:{
        green: props.row.riepenau_export == 1,
        grey: props.row.riepenau_export == 0,
      },attrs:{"icon":"new-tab","title":"Exportkunde via Riepenau"}})]}}])}),_c('b-table-column',{staticClass:"iconlink",attrs:{"label":"E","field":"link0","header-class":"iconlink"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.email)?_c('a',{attrs:{"href":`mailto:${props.row.email}`,"title":'E-Mail: ' + props.row.email}},[_c('b-icon',{attrs:{"icon":"email2"}})],1):_vm._e()]}}])}),_c('b-table-column',{staticClass:"iconlink",attrs:{"label":"A","field":"link1","header-class":"iconlink"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('a',{attrs:{"href":`/users/view/${props.row.id}`,"title":"Ansehen"},on:{"click":function($event){$event.preventDefault();return _vm.customerView(props.row.id)}}},[_c('b-icon',{attrs:{"icon":"find_in_page"}})],1)]}}])}),_c('b-table-column',{staticClass:"iconlink",attrs:{"label":"B","field":"link2","header-class":"iconlink"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('a',{attrs:{"href":`/users/edit/${props.row.id}`,"title":"Bearbeiten"},on:{"click":function($event){$event.preventDefault();return _vm.customerEdit(props.row.id)}}},[_c('b-icon',{attrs:{"icon":"square-edit-outline"}})],1)]}}])}),_c('b-table-column',{staticClass:"iconlink",attrs:{"label":"L","field":"link3","header-class":"iconlink"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('a',{attrs:{"title":"Löschen"},on:{"click":function($event){$event.preventDefault();return _vm.customerDelete(props.row.id, props.row.name)}}},[_c('b-icon',{attrs:{"icon":"delete_forever"}})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }