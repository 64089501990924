import Vue from 'vue'
import Buefy from 'buefy'
import App from './App.vue'
import Axios from './plugins/axios.js'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import {
  utilities
} from './utilities.js'

//import '@mdi/font/css/materialdesignicons.min.css'

//Konfigurations-Variablen im store speichern
import extranet from './config.js'
store.commit('setExtranetConfig', extranet)
Vue.config.productionTip = false
Vue.use(Buefy, {
  defaultIconPack: 'icm',
  defaultDialogConfirmText: 'Löschen',
  defaultDialogCancelText: 'Abbrechen',
  customIconPacks: {
    icm: {
      sizes: {
        default: 'is-size-5',
        'is-small': '',
        'is-medium': 'is-size-3',
        'is-large': 'is-size-1'
      },
      iconPrefix: 'icm-'
    }
  }
})


Vue.use(Vuelidate)

Vue.prototype.$utilities = utilities
Vue.prototype.$http = Axios
Vue.prototype.$http.interceptors.request.use(function (config) {

  //Schon eingeloggt
  if (store.getters.isLoggedIn === true) {
    //console.log('Axios-Interceptor Logged-in')
    config.headers.Authorization = `Bearer ${store.getters.jwtToken.token}`;
    config.headers['X-Facility'] = store.getters.facility;
    config.withCredentials = true;
  }

  //Neues Token anfordern
  if (store.getters.jwtToken.refreshRequested === true) {
    //console.log('Axios-Interceptor refreshToken')
    config.headers['Refresh-token'] = 1;
  }
  //console.log('Axios-Interceptor Ende')
  return config;
})


Vue.prototype.$http.interceptors.response.use(function (response) {
  //console.log('Axios-Interceptor Response')
  if (response.data.code == 1 && store.getters.jwtToken.refreshRequested === true && response.data.token.length > 100) {
    //console.log('Neues Token bekommen');
    var payload = store.parseJwToken(response.data.token);
    //console.log('Payload nach Parse')
    //console.log(payload)

    if (typeof payload.user == 'object' && typeof payload.user.permissions == 'object') {
      //console.log('erneuertes Token wird gespeichert.');
      store.commit('auth_success', store.prepareData(response.data.token, payload))
      //console.log('erneuertes Token ist gespeichert.');
    } else {
      //console.log('erneuertes Token ist kaputt');
    }
  }
  //console.log('Flash count ' + response.data.flash.length);
  //console.log(response.data.flash[0])
  if (typeof response.data.flash != 'undefined' && response.data.flash.length >= 1) {

    for (var i = 0; i < response.data.flash.length; i++) {
      var flash = response.data.flash[i];
      //console.log('In Schleife')
      //console.log(flash);
      if (flash[0] == 'success') {
        //console.log('Success');
        Vue.prototype.$buefy.toast.open({
          message: flash[1],
          type: 'is-success',
          duration: 5000
        })
      } else if (flash[0] == 'error') {
        Vue.prototype.$buefy.toast.open({
          message: flash[1],
          type: 'is-danger',
          duration: 5000
        })
      } else if (flash[0] == 'warning') {
        Vue.prototype.$buefy.tost.open({
          message: flash[1],
          type: 'is-warning',
          duration: 5000
        })
      } else {
        //console.log('Undefinierter Flash');
        Vue.prototype.$buefy.toast.open({
          message: flash[1],
          type: 'is-info',
          duration: 5000
        })
      }

    }
  }
  return response;
}, function (error) {

  if (typeof error.response != 'undefined' && error.response.status == 401) {
    //console.log('Server hat mit 401 reagiert, logout und Umleitung auf login')
    if (typeof error.response.data.flash != 'undefined' && error.response.data.flash.length >= 1) {
      for (var i = 0; i < error.response.data.flash.length; i++) {
        var flash = error.response.data.flash[i];
        //console.log('In Schleife')
        //console.log(flash);
        if (flash[0] == 'error') {

          Vue.prototype.$buefy.toast.open({
            message: flash[1],
            type: 'is-danger',
            duration: 5000
          })
        }
      }
    }

    store.commit('logout');
    //console.log(router.currentRoute.fullPath);
    if (router.currentRoute.fullPath != '/login') {
      router.push({
        path: '/login'
      });
    }
  } else {
    if (typeof error.response.data.flash != 'undefined' && error.response.data.flash.length >= 1) {
      for (var i = 0; i < error.response.data.flash.length; i++) {
        var flash = error.response.data.flash[i];
        //console.log('In Schleife')
        //console.log(flash);
        if (flash[0] == 'error') {

          Vue.prototype.$buefy.toast.open({
            message: flash[1],
            type: 'is-danger',
            duration: 8000
          })
        }
      }
    }

  }

  return Promise.reject(error);
});





new Vue({
  router,
  Axios,
  store,
  Buefy,
  utilities,
  beforeCreate: function () {

    if (window.location.pathname != '/' && window.location.pathname != '/login') {
      store.commit('setOriginalUrl', window.location.pathname);
    }

    //Wenn Token vorhanden, dann prüfen, ob noch gültig
    const jwToken = localStorage.getItem('jwToken')
    if (jwToken) {
      //console.log('Versuche bestehenden Token zu parsen')
      var payload = store.parseJwToken(jwToken);
      var ts = Math.floor(new Date().getTime() / 1000);
      //Token noch gültig
      if (typeof payload == 'object' && payload.exp > ts) {
        store.commit('auth_success', store.prepareData(jwToken, payload));
        //console.log('Mit altem noch gültigem Token eingeloggt');
        router.push(store.getters.originalUrl);
      } else {
        //console.log('Altes Token gefunden aber nicht mehr gültig. Gelöscht.');
        store.commit('logout');
        router.push({
          path: '/login'
        })
      }
    } else {
      //console.log('Kein Token gefunden. '+window.location.pathname.substring(0, 6));
      store.commit('created');

      if (window.location.pathname.substring(0, 6) != '/login') {
        //console.log('muss pushen');
        router.push({
          path: '/login'
        })
      } else {
        router.push({
          path: window.location.pathname
        })
      }
    }
    if (localStorage.getItem('facility')) {
      console.log('facility gefunden');
      store.commit('setFacility', localStorage.getItem('facility'));
    }
  },
  render: h => h(App)
}).$mount('#app')
