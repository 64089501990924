<template>
  <div class="card">
    <div class="card-header">
      <h1 class="card-header-title">
        Bitte wählen Sie eine Firma aus:
      </h1>
    </div>
    <div class="card-content has-text-centered">
      <a @click.prevent="changeFacility('wa')"><img src="/img/logo_weinauktion.png" alt="Weinauktionator" id="logo_wa" class="logo" /></a>
      <a @click.prevent="changeFacility('rp')"><img src="/img/logo_riepenau.png" alt="Riepenau" id="logo_rp"  class="logo" /></a>
      
    </div>
    <div class="card-footer">
      <div class="column">
        <button class="button" type="button" @click="$parent.close()">
          Schließen
        </button>
       
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FacilitySelector",
  
  methods: {
   changeFacility: function(newFacility) {
    
     if(this.$store.getters.facility != newFacility)
     {
       this.$store.commit('setFacility', newFacility);
       this.$parent.close()
       this.$router.push('/dashboard', {params: {t:Date.now(), query: {t:Date.now()}}});
     }
     else{
       this.$parent.close();
     }
    }
  }
}
</script>

<style>
.logo{
  height: 140px;
  padding: 30px;
}

</style>
