<template>
  <b-table
    :data="data.data"
    :loading="isLoading"
    :striped="true"
    :hoverable="true"
    :range-before="5"
    :range-after="5"
    paginated
    backend-pagination
    pagination-position="both"
    :total="data.count"
    :per-page="data.perpage"
    @page-change="onPageChange"
    :current-page.sync="data.page"
    aria-next-label="Weiter"
    aria-previous-label="Zurück"
    aria-page-label=" Seite "
    aria-current-label="Aktuelle Seite"
    backend-sorting
    :default-sort="[data.sortField, data.sortOrder]"
    @sort="onSort"
    icon-pack="icm"
    :mobile-cards="false"
    :row-class="(row, index) => (row.active == 1 ? 'green' : 'red')"
  >
    <b-table-column
      v-slot="props"
      field="id"
      label="Id"
      cell-class="status"
      numeric
      sortable
    >
      {{ $utilities.padLeftWithNull(props.row.id) }}
    </b-table-column>

    <b-table-column v-slot="props" field="name" label="Name" sortable>
      {{ props.row.name }}
    </b-table-column>

    <b-table-column v-slot="props" field="city" label="Ort" sortable>
      {{
        typeof props.row.country.code == "undefined" || props.row.country.code == null
          ? "??"
          : props.row.country.code
      }}
      » {{ props.row.zip }} {{ props.row.city }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Zuordn."
      field="link5"
      title="Zuordnung zu Firmen"
    >
      <span
        title="Kunde Weinauktionator"
        :class="{
          green: props.row.customer_wa == 1,
          grey: props.row.customer_wa == 0,
        }"
        >WA</span
      >&nbsp;
      <span
        title="Kunde Riepenau"
        :class="{
          green: props.row.customer_rp == 1,
          grey: props.row.customer_rp == 0,
        }"
        >RP</span
      >
    </b-table-column>
    <b-table-column
      v-slot="props"
      label="Status"
      field="link6"
      title="Lieferadresse/Export"
    >
      <b-icon
        icon="delivery"
        :class="{
          green: props.row.shipping_address == 1,
          grey: props.row.shipping_address == 0,
        }"
        title="Separate Lieferadresse"
      ></b-icon>

      <b-icon
        icon="new-tab"
        :class="{
          green: props.row.riepenau_export == 1,
          grey: props.row.riepenau_export == 0,
        }"
        title="Exportkunde via Riepenau"
      ></b-icon>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="E"
      class="iconlink"
      field="link0"
      header-class="iconlink"
    >
      <a
        :href="`mailto:${props.row.email}`"
        :title="'E-Mail: ' + props.row.email"
        v-if="props.row.email"
        ><b-icon icon="email2"></b-icon
      ></a>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="A"
      class="iconlink"
      field="link1"
      header-class="iconlink"
    >
      <a
        :href="`/users/view/${props.row.id}`"
        title="Ansehen"
        @click.prevent="customerView(props.row.id)"
        ><b-icon icon="find_in_page"></b-icon
      ></a>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="B"
      class="iconlink"
      field="link2"
      header-class="iconlink"
    >
      <a
        :href="`/users/edit/${props.row.id}`"
        title="Bearbeiten"
        @click.prevent="customerEdit(props.row.id)"
        ><b-icon icon="square-edit-outline"></b-icon
      ></a>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="L"
      class="iconlink"
      field="link3"
      header-class="iconlink"
    >
      <a @click.prevent="customerDelete(props.row.id, props.row.name)" title="Löschen"
        ><b-icon icon="delete_forever"></b-icon
      ></a>
    </b-table-column>
  </b-table>
</template>

<script>
export default {
  name: "customerList",
  data() {
    return {
      error: "",
    };
  },
  computed: {
    data: function () {
      return this.$parent.data;
    },
    isLoading: function () {
      return this.$parent.isLoading;
    },
  },
  created: function () {
    //console.log("Übersicht")
    this.$store.commit("setHeadline", {
      subheadline: "Übersicht",
      add: "/customers/add",
      submenu: [],
    });
  },

  methods: {
    onPageChange(page) {
      this.$parent.data.page = page;
      this.$parent.customerList();
    },
    /*
     * Handle sort event
     */
    onSort(field, direction) {
      //console.log(field + ' » ' + direction)
      this.data.sortField = field;
      this.data.sortOrder = direction;
      this.$parent.data.order = { field, direction };
      this.$parent.customerList();
    },
    customerDelete: function (id, name) {
      this.$parent.customerDelete(id, name);
    },

    customerView: function (id) {
      this.$parent.customerView(id);
    },
    customerEdit: function (id) {
      this.$parent.customerEdit(id);
    },
  },
};
</script>
