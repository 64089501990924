export const utilities = {
  label: [
    {
      id: "pf",
      name: "perfect",
    },
    {
      id: "dm",
      name: "damaged",
    },
    {
      id: "di",
      name: "dirty",
    },
    {
      id: "fa",
      name: "faded",
    },
    {
      id: "no",
      name: "none",
    },
  ],
  packaging: [
    {
      id: "ohk",
      name: "Original wooden case",
    },
    {
      id: "ahk",
      name: "Other wooden case",
    },
    {
      id: "ok",
      name: "Original carton",
    },
    {
      id: "ak",
      name: "Other carton",
    },
    {
      id: "no",
      name: "none",
    },
  ],
  capsula: [
    {
      id: "pf",
      name: "perfect",
    },
    {
      id: "dm",
      name: "damaged",
    },
    {
      id: "no",
      name: "none",
    },
  ],
  fill_level: [
    {
      id: "hf",
      name: "high fill",
    },
    {
      id: "in",
      name: "into neck",
    },
    {
      id: "ts",
      name: "top shoulder",
    },
    {
      id: "hs",
      name: "high shoulder",
    },
    {
      id: "ms",
      name: "mid shoulder",
    },
    {
      id: "lms",
      name: "lower mid shoulder",
    },
    {
      id: "ls",
      name: "low shoulder",
    },
  ],
  rater: [
    {
      id: 1,
      name: "Robert Parker",
      max: 100,
    },
    {
      id: 3,
      name: "Wine Spectator",
      max: 100,
    },
    {
      id: 14,
      name: "Jancis Robinson",
      max: 20,
    },
    {
      id: 16,
      name: "Allen Meadow",
      max: 100,
    },
  ],
  invoicePositionTypes: {
    wa: [
      {
        id: "auction",
        name: "Gewonnenes Lot",
        showOnInvoiceCreation: 0,
      },
      {
        id: "auctioncredit",
        name: "Gutschrift zu gewonnenem Lot",
        showOnInvoiceCreation: 1,
      },
      {
        id: "aftersale",
        name: "Nachverkauf",
        showOnInvoiceCreation: 1,
      },
      {
        id: "ship",
        name: "Versandkosten (inkl. Verpackung)",
        showOnInvoiceCreation: 1,
      },
      {
        id: "misc",
        name: "Sonstiges",
        showOnInvoiceCreation: 1,
      },
    ],
    rp: [
      {
        id: "wine",
        name: "Wein",
        showOnInvoiceCreation: 1,
      },
      {
        id: "wainvoice",
        name: "WeinAuktionator-Rechnung",
        showOnInvoiceCreation: 1,
      },

      {
        id: "ship",
        name: "Versandkosten (inkl. Verpackung)",
        showOnInvoiceCreation: 1,
      },
      {
        id: "misc",
        name: "Sonstiges",
        showOnInvoiceCreation: 1,
      },
    ],
  },

  paymentTypes: [
    {
      id: "cash",
      name: "Bar",
    },
    {
      id: "banktransfer",
      name: "Überweisung",
      showOnInvoiceCreation: 1,
    },
    {
      id: "Paypal",
      name: "Paypal",
    },
    {
      id: "Stripe",
      name: "Stripe",
    },
  ],
  dateFormatToIso: (date) => {
    if (date instanceof Date) {
      var m = date.getMonth() + 1;
      if (m < 10) {
        m = "0" + m;
      }

      var d = date.getDate();
      if (d < 10) {
        d = "0" + d;
      }
      return date.getFullYear() + "-" + m + "-" + d;
    } else {
      return null;
    }
  },

  formatUnixtimeToDmYHi: (ts, onlyDmY = false) => {
    var d = new Date(ts * 1000);
    var fd = "";
    //Tag
    var p = d.getDate();
    if (p < 10) {
      p = "0" + p;
    }
    fd += p + ".";
    //Monat
    p = d.getMonth() + 1;
    if (p < 10) {
      p = "0" + p;
    }
    //Jahr
    fd += p + "." + d.getFullYear() + " ";

    if (onlyDmY === false) {
      //Stunden
      p = d.getHours();
      if (p < 10) {
        p = "0" + p;
      }
      fd += p + ":";
      //Minuten
      p = d.getMinutes();
      if (p < 10) {
        p = "0" + p;
      }
      fd += p;
    }
    return fd;
  },
  createRackList: (racks, trays) => {
    var list = [];
    var chr = "";
    var k = "";
    for (var r = 0; r < racks; r++) {
      for (var t = 1; t <= trays; t++) {
        chr = String.fromCharCode(65 + r);

        k = chr + t;
        list.push({
          id: k,
          name: "Regal " + chr + ", Fach " + t,
        });
      }
    }

    return list;
  },
  padLeftWithNull(n, l = 5) {
    if (typeof n == "undefined" || n == null) {
      return "";
    }
    var f = n.toString();
    var rl = f.length;
    if (rl < l) {
      f = "0".repeat(l - rl) + f;
    }
    return f;
  },
  getValue: function (list, value) {
    list = this[list];
    for (var c = 0; c < list.length; c++) {
      if (list[c].id == value) {
        return list[c].name;
      }
    }

    return "unbekannt";
  },
  getRater: function (value) {
    var list = this.rater;
    for (var c = 0; c < list.length; c++) {
      if (list[c].id == value) {
        return list[c].name;
      }
    }

    return "unbekannt";
  },

  getList: function (list, facility = false) {
    if (
      facility != false &&
      facility.length == 2 &&
      typeof this[list][facility] != "undefined"
    ) {
      return this[list][facility];
    }
    return this[list];
  },
  shortenString(s, l = 30, e = ".") {
    if (s == null) {
      return "";
    } else if (s.length > l) {
      return s.substring(0, l - 2) + e;
    }
    return s;
  },
};
