<template>
  <transition name="contenttrans" mode="out-in" appear>
      <router-view />
    </transition>
</template>

<script>


const AuctioneventList = (resolve) => {
  require.ensure(
    ["./auctioneventslist.vue"],
    () => {
      resolve(require("./auctioneventslist.vue"))
    },
    "auctions"
  )
}

const AuctioneventForm = (resolve) => {
  require.ensure(
    ["./auctioneventsform.vue"],
    () => {
      resolve(require("./auctioneventsform.vue"))
    },
    "auctions"
  )
}

const AuctioneventView = (resolve) => {
  require.ensure(
    ["./auctioneventsview.vue"],
    () => {
      resolve(require("./auctioneventsview.vue"))
    },
    "auctions"
  )
}

const AuctioneventStats = (resolve) => {
  require.ensure(
    ["./auctioneventsstats.vue"],
    () => {
      resolve(require("./auctioneventsstats.vue"))
    },
    "auctions"
  )
}

export const auctionEventsRoutes = [
  {
    path: "",
    redirect: "list"
  },
  {
    path: "list",
    name: "AuctionEventsList",
    component: AuctioneventList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "view/:id",
    name: "AuctionEventsView",
    component: AuctioneventView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "stats/:id",
    name: "AuctionEventsStats",
    component: AuctioneventStats,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "add",
    name: "AuctionEventsAdd",
    component: AuctioneventForm,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "edit/:id",
    name: "AuctionEventsEdit",
    component: AuctioneventForm,
    meta: {
      requiresAuth: true
    }
  }
]

export default {
  name: "AuctionEvents",
  components: {
    AuctioneventList,
    AuctioneventForm,
    AuctioneventView,
    AuctioneventStats
  },
  
  created: function() {
    //console.log('Modul Auktionen geladen');
  },
  methods: {
   del1: function(id, name) {
      this.$buefy.dialog.confirm({
        title: 'Bitte bestätigen!',
        message: 'Wollen Sie Datensatz #' + id + ' "' + name + '" wirklich löschen?',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.del2(id)
      })
    },
    del2: function(id) {
      //console.log('delete '+id);
      var t = this
      this.$http
        .post('/auctionevent/delete/' + id, {
          page: 1,
        })
        .then(function(resp) {
          t.$children[0].auctionList(resp.data.data);
          return true
        })
        .catch(function(error) {
          //console.log('Shithappens')
          //console.log(error)
          if (typeof error.response != 'undefined' && typeof error.response.data.message != 'undefined') {
            t.$buefy.toast.open({
              message: error.response.data.message,
              type: 'is-danger',
              duration: 5000
            })
          } else {
            t.$buefy.toast.open({
              message: 'Server (API) nicht erreichbar. Bitte versuchen Sie es später erneut.',
              type: 'is-danger',
              duration: 5000
            })
          }
        })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss"></style>
