<template>
  <div>
    <transition name="contenttrans" mode="out-in" appear>
      <router-view />
    </transition>
  </div>
</template>

<script>
import CancelAuction from "../_helpers/cancelauction.vue"

const LotsList = (resolve) => {
  require.ensure(
    ["./lotslist.vue"],
    () => {
      resolve(require("./lotslist.vue"))
    },
    "auctions"
  )
}

const LotsAdd = (resolve) => {
  require.ensure(
    ["./lotsadd.vue"],
    () => {
      resolve(require("./lotsadd.vue"))
    },
    "auctions"
  )
}

const LotsView = (resolve) => {
  require.ensure(
    ["./lotsview.vue"],
    () => {
      resolve(require("./lotsview.vue"))
    },
    "auctions"
  )
}

export const auctionLotsRoutes = [
  {
    path: "",
    redirect: "list"
  },
  {
    path: "list",
    name: "LotsList",
    component: LotsList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "view/:id",
    name: "LotsView",
    component: LotsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "addlot/:id",
    name: "LotsAddLot",
    component: LotsAdd,
    meta: {
      requiresAuth: true
    }
  }
]

export default {
  name: "AuctionLots",
  components: {
    LotsList,
    LotsAdd,
    LotsView
  },
  data() {
    return {
      data: [],
      error: ""
    }
  },
  created: function() {
    //console.log('Modul Auktionen geladen');
  },
  methods: {
    search: function() {
      //console.log("index")
      this.$children[1].search()
    },
    cancel1: function(id, title) {
      var t = this
      t.$buefy.modal.open({
        parent: t,
        component: CancelAuction,
        hasModalCard: false,
        width: 960,
        props: {
          id: id,
          title: title
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss"></style>
