<template>
  <div>
    <ex4-headline />
    <transition name="contenttrans" mode="out-in" appear>
      <section>
        <b-tabs
          type="is-boxed"
          @input="changeTab"
          v-model="currentTab"
          destroy-on-hide
        >
          <template v-for="tab in tabs" >
            <b-tab-item :key="tab.key" :value="tab.key" :label="tab.name" ref="tabcontent">
            </b-tab-item>
          </template>
        </b-tabs>
        <router-view />
      </section>
    </transition>
  </div>
</template>

<script>
import Ex4Headline from "../_helpers/headline.vue"
import CancelAuction from "../_helpers/cancelauction.vue"
import Lots, { auctionLotsRoutes } from "./lots.vue"
import Auctionevents, { auctionEventsRoutes } from "./auctionevents.vue"

const AuctionBids = (resolve) => {
  require.ensure(
    ["./bids.vue"],
    () => {
      resolve(require("./bids.vue"))
    },
    "auctions"
  )
}

const AuctionOnwatchlists = (resolve) => {
  require.ensure(
    ["./onwatchlists.vue"],
    () => {
      resolve(require("./onwatchlists.vue"))
    },
    "auctions"
  )
}

const AuctionLiveauction = (resolve) => {
  require.ensure(
    ["./liveauction.vue"],
    () => {
      resolve(require("./liveauction.vue"))
    },
    "auctions"
  )
}

export const auctionRoutes = [
  {
    path: "",
    redirect: "lots"
  },
  {
    path: "auctions",
    component: Auctionevents,
    meta: {
      requiresAuth: true
    },
    children: auctionEventsRoutes
  },
  {
    path: "lots",
    component: Lots,
    meta: {
      requiresAuth: true
    },
    children: auctionLotsRoutes
  },
  {
    path: "bids",
    name: "auctionBids",
    component: AuctionBids,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "onwatchlists",
    name: "auctionOnwatchlists",
    component: AuctionOnwatchlists,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "liveauction",
    name: "auctionLiveauction",
    component: AuctionLiveauction,
    meta: {
      requiresAuth: true
    }
  }
]

export default {
  name: "Auctions",
  components: {
    Lots,
    Auctionevents,
    AuctionBids,
    AuctionLiveauction,
    AuctionOnwatchlists,
    AuctionLiveauction,
    Ex4Headline
  },
  data() {
    return {
      data: [],
      error: "",
      subtitle: "",
      currentTab: "lots",
      tabs: [
        { key: "auctions", name: "Auktionen" },
        { key: "lots", name: "Lots" },
        { key: "bids", name: "Verlauf Gebote" },
        { key: "onwatchlists", name: "Auf Beobachtungslisten" },
        { key: "liveauction", name: "Live-Auktion" }
      ]
    }
  },
  created: function() {
    this.$store.commit("setHeadline", {
      headline: "Auktionen",
      subheadline: "",
      search: false,
      add: false,
      submenu: false,
      icon: "gavel"
    })

    var currentRoute = this.$router.currentRoute.fullPath.split("/")
    if (currentRoute[2].length > 2) {
      for (var i = 0; i <= this.tabs.length; i++) {
        if (this.tabs[i].key == currentRoute[2]) {
          this.currentTab = currentRoute[2]
          break
        }
      }
    }
    //console.log('Modul Auktionen geladen');
  },
  methods: {
    changeTab(e) {

      if(this.$router.currentRoute.fullPath == '/auctions/liveauction')
      {
        this.$root.$emit('closeWSS');
      }
      this.$router
        .push({
          path: "/auctions/" + e
        })
        .catch((err) => {
          //console.log("fehler")
        })
    },

    search: function() {
      //console.log("index")
      this.$children[1].search()
    },
    cancel1: function(id, title) {
      var t = this
      t.$buefy.modal.open({
        parent: t,
        component: CancelAuction,
        hasModalCard: false,
        width: 960,
        props: { id: id, title: title }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
