module.exports = {
  'page': {
    logo: {
      wa: '/img/logo_weinauktion.png',
      rp: '/img/logo_riepenau.png'
    },
    icon: {
      wa: '/img/icon_weinauktionator.png',
      rp: '/img/icon_riepenau.png'
    },
    backendBaseUrl: (window.location.hostname.indexOf('.loc') >= 2) ? 'http://backend.weinauktionator.loc/v1' : 'https://backend.weinauktionator.eu/v1',
    apiBaseUrl: (window.location.hostname.indexOf('.loc') >= 2) ? 'http://api.weinauktionator.loc/v1' : 'https://api.weinauktionator.eu/v1',
    imageBaseUrl: (window.location.hostname.indexOf('.loc') >= 2) ? 'http://api.weinauktionator.loc' : 'https://api.weinauktionator.eu',

  },
  'stock': {
    racks: 15,
    traysPerRack: 9

  },
  'owner': {
    'company': {wa: 'Weinauktionator by W+K', rp: 'Riepenau Fine + Rare GmbH'},
    'address': 'Beethovenstraße 21',
    'zip': '24568',
    'city': 'Kaltenkirchen'
  },
  'development': {
    'supportEmail': 'support@haeger.de',
    'supportTel': '+494821439940',
    'company': 'haeger new media',
    'version': '1.12.23'
  },
  vat: 19,
  urlsWithoutLogin: ['/login', '/login/password', '/login/imprint', '/login/npw/*']
}
