<template>
  <div>
    <ex4-headline @submenuclicked="subMenuHandler" />
    <transition name="contenttrans" mode="out-in" appear>
      <router-view />
    </transition>
  </div>
</template>

<script>
import Ex4Headline from "../_helpers/headline.vue";

const StockList = (resolve) => {
  require.ensure(
    ["./list.vue"],
    () => {
      resolve(require("./list.vue"));
    },
    "stocks"
  );
};

const StockView = (resolve) => {
  require.ensure(
    ["./view.vue"],
    () => {
      resolve(require("./view.vue"));
    },
    "stocks"
  );
};

export const stockRoutes = [
  {
    path: "",
    redirect: "index",
  },
  {
    path: "index",
    name: "stockList",
    component: StockList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "view/:id",
    name: "stockView",
    component: StockView,
    meta: {
      requiresAuth: true,
    },
  },
];

export default {
  name: "Stocks",
  components: {
    StockList,
    StockView,
    Ex4Headline,
  },
  data() {
    return {
      searchphrase: "",
    };
  },

  created: function () {

    var submenu = [{ name: "Excel-Export", link: "excel" }];
    if (this.$store.getters.facility == "wa")
    {
      submenu.push({ name: "Verfügbarer Bestand alles", link: "excelAvailableAll" });
      submenu.push({ name: "Verfügbarer Bestand Riepenau", link: "excelAvailableOwn" });

    }
    this.$store.commit("setHeadline", {
      headline: "Lagerbestände",
      search: "search",
      submenu: submenu,
      icon: "package-variant-closed",
    });
  },
  methods: {
    search: function () {
      var t = this;
      if (t.$router.currentRoute.fullPath != "/stocks/index") {
        t.$router.push("/stocks/index");
      } else {
        t.$children[1].search();
      }
    },
    subMenuHandler(link) {
      if (link == "excel") {
        this.getExcelExport();
      } else if (link == "excelAvailableAll") {
        this.getExcelAvailable("all");
      } else if (link == "excelAvailableOwn") {
        this.getExcelAvailable('own');
      }
    },
    getExcelExport() {
      var url = this.$store.getters.config.page.apiBaseUrl + "/stocks/getexcelfile/";
      this.$http
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/xlsx" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "lager" + ".xlsx";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    getExcelAvailable (type = "all") {
      var t = this;
      var url =
        t.$store.getters.config.page.apiBaseUrl + "/stocks/getexcelavailable/" + type;
      t.$http
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/xlsx" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "stock_available_" + type.substring(0, 1) + '_'+t.$utilities.dateFormatToIso(new Date)+ ".xlsx";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
